<template>
  <div class="visit-detail-page">
    <Header />
    <div class="card-container">
      <Card class="card" :info="info" :clickable="false"></Card>
    </div>
  </div>
</template>

<script>
import Header from '@/components/header/index.vue'
import Card from './components/listCard.vue'

export default {
  components: {
    Header,
    Card
  },
  data() {
    return {
      info: {}
    }
  },
  beforeMount() {
    this.info = this.$route.params.info
  }
}
</script>

<style lang="scss" scoped>
.visit-detail-page {
  width: 100%;
  min-height: 100vh;
  background: #FAFBFC;
  .card-container {
    width: 100%;
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>