<template>
  <div class="container" @click="play">
    <img :src="VoiceIcon" class="voice-icon" />
    <div class="duration">{{duration}}'</div>
    <audio ref="audio" :src="src"></audio>
  </div>
</template>

<script>
import VoiceIcon from '@/assets/images/common/voice.png'

export default {
  props: {
    duration: {
      type: Number,
      default: 0
    },
    src: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      VoiceIcon,
      inPlay: false,
    }
  },
  methods: {
    play() {
      if (this.inPlay) {
        this.$refs.audio.pause()
        this.inPlay = false
      }
      else {
        this.$refs.audio.play()
        this.inPlay = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 128px;
  height: 29px;
  background: #CD001F;
  opacity: 1;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  .voice-icon {
    width: 15px;
  }
  .duration {
    font-size: 14px;
    color: white;
  }
  .audio {
    position: absolute;
    width: 128px;
    height: 29px;
    left: 0;
    top: 0;
  }
}
</style>