<template>
  <div class="card" @click="handleClick">
    <div class="date-name-container">
      <div class="date">{{info.updateTime || info.createTime || '--'}}</div>
      <div class="name">{{info.createByName || info.createName || '--'}}</div>
    </div>
    <div class="summary-container">拜访小结<div class="summary">{{info.visitTip || '--'}}</div></div>
    <Recorder class="recorder" v-if="info.visitVoice" :src="info.visitVoice" :duration="info.visitVoiceLength"></Recorder>
    <ImageViewer class="image-viewer" :upload="false" :images="info.visitPhoto ? info.visitPhoto.split(',') : []"></ImageViewer>
  </div>
</template>

<script>
import Recorder from '@/components/Recorder/recorderDisplay.vue'
import ImageViewer from '@/components/common/imageViewer/index.vue'

export default {
  components: {
    Recorder,
    ImageViewer,
  },
  props: {
    info: {
      type: Object,
      default: ()=>{
        return {}
      }
    },
    clickable: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    handleClick() {
      if (this.clickable){
        this.$router.push({
          name: 'VisitDetail',
          params: {
            info: this.info
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  width: 351px;
  box-sizing: border-box;
  padding: 12px 12px 14px;
  background: white;
  box-shadow: 0px 0px 7px rgba(235, 235, 235, 0.62);
  opacity: 1;
  border-radius: 10px;
  font-size: 14px;
  .date-name-container {
    text-align: left;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #2C2C2C;
    .name {
      margin-left: 12px;
    }
  }
  .summary-container {
    margin-top: 6px;
    height: 17px;
    line-height: 17px;
    font-size: 12px;
    color: #818181;
    display: flex;
    .summary {
      color: #2C2C2C;
      margin-left: 12px;
    }
  }
  .recorder {
    margin-top: 10px;
  }
  .image-viewer {
    margin-top: 13px;
  }

}
</style>