<template>
  <div class="visit-list-page">
    <Header />
    <div class="card-container">
      <Card class="card" style="margin-bottom: 12px;" v-for="(item,index) in list" :key="index" :info="item"></Card>
    </div>
    <infinite-loading ref="infinite" @infinite="infiniteHandler">
      <span slot="no-more">
        <!-- 暂无更多 -->
      </span>
    </infinite-loading>
  </div>
</template>

<script>
import Header from '@/components/header/index.vue'
import Card from './components/listCard.vue'
import InfiniteLoading from 'vue-infinite-loading'
import {
  fetchSomeoneRecentVisitList,
  fetchBlankVillageVisitList,
  fetchBigFarmersVisitList,
  fetchDistBigFarmersVisitList,
  fetchPotentialVisitList,
  fetchDistPotentialVisitList,
  fetchModelfieldList,
  fetchDistModelfieldList,
  fetchTerminalVisitList,
  fetchDistTerminalVisitList,
} from "@/api/visit";

export default {
  components: {
    Header,
    Card,
    InfiniteLoading,
  },
  data() {
    return {
      list: [],
      pageNo: 1,
      pageSize: 5,
      total: 0,
      userId: '',
      // 类别
      type: '',
      tab: '', // shidanli || ziyou
    }
  },
  methods: {
    async infiniteHandler($state) {
      try {
        if (this.userId) {
          await this.getSomeoneVisitList()
        }
        // 示范田
        else if(this.type === 'demonstrationField'){
          await this.getModelfieldVisitList()
        }
        // 种植大户
        else if(this.type === 'bigGrowers') {
          await this.getBigFarmersVisitList()
        }
        // 潜在客户
        else if(this.type === 'potentialCustomers') {
          await this.getPotentialVisitList()
        }
        // 空白村
        else if(this.type === 'emptyArea') {
          await this.getBlankVillageVisitList()
        }
        // 终端
        else if(this.type === 'terminalOutlets') {
          await this.getTerminalVisitList()
        }
        $state.loaded();
        this.pageNo += 1;
        if (this.total - this.list.length === 0) {
          $state.complete();
        }
      } catch (e) {
        $state.loaded();
        $state.complete();
      }
    },
    async getSomeoneVisitList() {
      let response = []
      let data = {
        "userId": this.userId,
        "page.pageNo": this.pageNo,
        "page.pageSize": this.pageSize,
      }
      response = await fetchSomeoneRecentVisitList(data)
      this.list = this.list.concat(response.data || [])
      this.total = (response.page && response.page.total) || 0
    },
    async getBigFarmersVisitList() {
      let response = []
      let data = {
        "id": this.id,
        "page.pageNo": this.pageNo,
        "page.pageSize": this.pageSize,
        "page.orderBy": "",
        "coordinate": ""
      }
      if (this.tab === 'shidanli') {
        response = await fetchBigFarmersVisitList(data)
      } else {
        response = await fetchDistBigFarmersVisitList(data)
      }
      this.list = this.list.concat(response.data || []);
      this.total = (response.page && response.page.total) || 0
    },
    async getPotentialVisitList() {
      let response = []
      let data = {
        "kjPotentialCustomers": this.id,
        "page.pageNo": this.pageNo,
        "page.pageSize": this.pageSize,
        "page.orderBy": "",
        "coordinate": ""
      }
      if (this.tab === 'shidanli') {
        response = await fetchPotentialVisitList(data)
      } else {
        response = await fetchDistPotentialVisitList(data)
      }
      this.list = this.list.concat(response.data || [])
      this.total = (response.page && response.page.total) || 0
    },
    async getModelfieldVisitList() {
      let response = []
      let data = {
        "id": this.id,
        "page.pageNo": this.pageNo,
        "page.orderBy": "",
        "page.pageSize": this.pageSize,
      }
      if (this.tab === 'shidanli') {
        response = await fetchModelfieldList(data)
      } else {
        response = await fetchDistModelfieldList(data)
      }
      this.list = this.list.concat(response.data || []);
      this.total = (response.page && response.page.total) || 0;
    },
    async getTerminalVisitList() {
      let response = []
      let data = {
        "kjTerminalNodeId": this.id,
        "kjTerminalNode": this.id,
        "page.pageNo": this.pageNo,
        "page.pageSize": this.pageSize,
      }
      if (this.tab === 'shidanli') {
        response = await fetchTerminalVisitList(data)
      } else {
        response = await fetchDistTerminalVisitList(data)
      }
      this.list = this.list.concat(response.data || []);
      this.total = (response.page && response.page.total) || 0;
    },
    async getBlankVillageVisitList() {
      let response = []
      let data = {
        "id": this.id,
        "page.pageNo": this.pageNo,
        "page.pageSize": this.pageSize,
      }
      response = await fetchBlankVillageVisitList(data)
      this.list = this.list.concat(response.data || []);
      this.total = (response.page && response.page.total) || 0;
    }

  },
  mounted() {
    this.userId = this.$route.query.userId || ''
    this.type = this.$route.query.type || ''
    this.tab = this.$route.query.tab || ''
    this.id = this.$route.query.id || ''
  },
  deactivated() {
    this.list = []
    this.pageNo = 1
    this.total = 0 
  },
}
</script>

<style lang="scss" scoped>
.visit-list-page {
  width: 100%;
  min-height: 100vh;
  background: #FAFBFC;
  .card-container {
    width: 100%;
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>